import clsx from 'clsx'
import React, {ChangeEvent, useEffect, useMemo, useState, useCallback} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {KTSVG} from '../../components'
import {fileHelper, formatBytes} from '../../helpers'
import {Button} from '../form/Button'

type FileFormat = {
  label: string
  value: string
}

type Props = {
  className?: any
  supportedFormat: FileFormat[]
  maxRow?: number
  maxUpload?: number
  size: {
    label: string
    value: number
  }
  onSubmit: (data: {files: File[]}) => void
  reloadForm?: boolean
  multiple?: boolean
}

const UploadFile: React.FC<Props> = ({
  className,
  supportedFormat = [],
  maxRow = 0,
  maxUpload = 0,
  size,
  onSubmit,
  reloadForm = false,
  multiple = false,
}) => {
  const intl = useIntl()
  const [fileInput, setFileInput] = useState<File[]>([])

  const fileType = useMemo(() => {
    return {
      label: supportedFormat.map((item) => item.label).join(', '),
      value: new Set(supportedFormat.map((item) => item.value)),
    }
  }, [supportedFormat])

  const setFile = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files?.length) return
      const files = multiple ? Array.from(e.target.files) : [e.target.files[0]]
      e.target.value = ''

      if (maxUpload && files.length + fileInput.length > maxUpload) {
        return toast.warning(
          intl.formatMessage({id: 'UPLOAD_UP_TO_INPUT_FILES'}, {input: maxUpload})
        )
      }

      const validFiles: File[] = []
      for (const file of files) {
        if (!fileType.value.has(file.type)) {
          toast.error(
            intl.formatMessage(
              {id: 'FILE_INPUT_HAS_INVALID_EXTENSION_ONLY_EXTENSION_ARE_ALLOWED'},
              {fileName: file.name, extension: fileType.label}
            )
          )
          continue
        }
        if (maxRow) {
          const {row} = await fileHelper.checkRowHaveData(file)
          if (row > maxRow) {
            toast.error(
              intl.formatMessage({id: 'CANNOT_UPLOAD_MORE_THAN_INPUT_ITEMS'}, {input: maxRow})
            )
            continue
          }
        }
        if (file.size > size.value) {
          toast.warning(
            intl.formatMessage(
              {id: 'FILE_INPUT_ERROR_UPLOADING_PLEASE_UPLOAD_FILES_SMALLER_THAN_INPUT'},
              {fileName: file.name, size: size.label}
            )
          )
          continue
        }
        validFiles.push(file)
      }

      if (validFiles.length > 0) {
        setFileInput((prev) => (multiple ? [...validFiles, ...prev] : validFiles))
        onSubmit({
          files: multiple ? [...validFiles, ...fileInput] : validFiles,
        })
      }
    },
    [fileInput, fileType, intl, maxRow, maxUpload, multiple, onSubmit, size]
  )

  const handleDelete = useCallback(
    (index: number) => {
      const updatedFiles = fileInput.filter((_, i) => i !== index)
      setFileInput(updatedFiles)
      onSubmit({files: updatedFiles})
    },
    [fileInput, onSubmit]
  )

  useEffect(() => {
    if (reloadForm) {
      setFileInput([])
    }
  }, [reloadForm])

  return (
    <>
      <div className={clsx('dropzone', className)}>
        <div className='d-flex justify-content-center my-4'>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className='fs-4 fw-bold text-dark mb-1'>
              {intl.formatMessage({id: 'DRAG_AND_DROP_HERE_OR'})}
              <span className='ms-2 text-primary'>{intl.formatMessage({id: 'BROWSE_FILES'})}</span>
            </div>
            <Button
              className='btn btn-secondary mt-4'
              label={intl.formatMessage({id: 'UPLOAD_FILE'})}
            />
          </div>
          <input
            type='file'
            id='file-upload'
            name='file-img'
            onChange={setFile}
            className='dropzone__upload mt-n10 cursor-pointer'
            multiple={multiple}
            accept={supportedFormat.map((item) => item.value).join(',')}
            disabled={maxUpload > 0 && fileInput.length >= maxUpload}
          />
        </div>
      </div>
      {fileInput.map((item: any, index) => (
        <div key={index} className='d-flex align-items-center mt-2 col-6'>
          <div className='flex-fill d-flex justify-content-between bg-light-primary p-3 rounded-2 fw-bold w-75'>
            <span className='text-truncate'>{item?.name}</span>
            <span className='text-nowrap'>{formatBytes(item?.size)}</span>
          </div>
          <div
            className='text-hover-danger cursor-pointer ms-4'
            onClick={() => handleDelete(index)}
          >
            <KTSVG path='/media/gori/common/empty.svg' small={false} />
          </div>
        </div>
      ))}
    </>
  )
}

export {UploadFile}
