import clsx from 'clsx'
import {compact, find, isEmpty, size} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useParams, useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {CSSTransition} from 'react-transition-group'
import {ConfirmActionModal, KTSVG} from '../../../../../gori/components'
import {SkeletonA1BatchDetail} from '../../../../../gori/components/skeleton/SkeletonA1BatchDetail'
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  FORMAT_DATE,
  OPTION_COUNTRIES,
  TABLE_KEY_COLUMN,
} from '../../../../../gori/constants'
import {
  convertUserTimeZone,
  getFilteredSearchParams,
  setLoadingActionTable,
} from '../../../../../gori/helpers'
import {useCancelToken, UsePermission} from '../../../../../gori/hooks'
import {PageTitle} from '../../../../../gori/layout'
import {useA1BatchesProvider, useAuth, useDisabled} from '../../../../../gori/providers'
import {
  Button,
  ButtonBack,
  Table,
  TableBody,
  TableWrapper,
  ValidationErrorModal,
} from '../../../../../gori/widgets'
import {
  A1_CONSTANTS,
  A1BatchesDetailFilter,
  A1BatchRenameModal,
  A1BatchService,
  A1Helpers,
  ConfirmA1BatchModal,
  EditOrderInfo,
  JourneyInfoCard,
  JourneyInformationModal,
  JourneyStatusCard,
  T86DocumentModal,
  UploadFileModal,
} from '../../../a1'

const A1BatchesDetailPage: React.FC = () => {
  const intl = useIntl()
  const {id} = useParams()
  const [searchParams] = useSearchParams()
  const {setPageDisabled} = useDisabled()
  const {currentUser} = useAuth()
  const {isOrigin, isDestination} = UsePermission()
  const {newCancelToken, isCancel} = useCancelToken()
  const {a1Batch, setA1Batch, reloadTableDetail} = useA1BatchesProvider()
  const [tableData, setTableData] = useState<any>([])
  const [tableDataAll, setTableDataAll] = useState<any>([])
  const [pagination, setPagination] = useState<any>({})
  const [validationErrors, setValidationErrors] = useState<any>()
  const [selectedListId, setSelectedListId] = useState<any>([])
  const [selectedMaster, setSelectedMaster] = useState<boolean>(false)
  const [loading, setLoading] = useState<{
    first: boolean
    getA1Batch: boolean
    markAsPickedUp: boolean
    setDropOff: boolean
    markAsDroppedOff: boolean
    notifyDestination: boolean
    massAsShipped: boolean
  }>({
    first: true,
    getA1Batch: false,
    markAsPickedUp: false,
    setDropOff: false,
    markAsDroppedOff: false,
    notifyDestination: false,
    massAsShipped: false,
  })
  const [showModal, setShowModal] = useState<{
    rename: boolean
    cancelBatch: boolean
    confirmPickUpdate: boolean
    journeyInfo: boolean
    uploadFileModal: boolean
    editOrderInfo: boolean
    t86DocumentModal: boolean
    confirmJourneyStatus: boolean
  }>({
    rename: false,
    cancelBatch: false,
    confirmPickUpdate: false,
    journeyInfo: false,
    uploadFileModal: false,
    editOrderInfo: false,
    t86DocumentModal: false,
    confirmJourneyStatus: false,
  })
  const [dataModal, setDataModal] = useState<{
    editOrderInfo: any
    confirmJourneyStatus: {
      title?: string | null
      message?: any
      callback: any
    }
  }>({
    editOrderInfo: {},
    confirmJourneyStatus: {title: null, message: null, callback: null},
  })

  const {READY, PICK_UP_DROP_OFF, AIR_DELIVERY, CUSTOMS} = A1_CONSTANTS.OPTIONS_JOURNEY_STATUS

  const flights = useMemo(() => {
    return a1Batch?.journey_flight_infos?.reduce(
      (acc, flight) => ({
        ...acc,
        [flight.type]: flight,
      }),
      {}
    )
  }, [a1Batch?.journey_flight_infos])

  const setTable = useCallback(async () => {
    try {
      setPageDisabled(true)
      const config = {
        params: {
          batch_id: id,
          page: searchParams.get('page') || DEFAULT_PAGE,
          page_size: searchParams.get('page_size') || DEFAULT_PAGE_SIZE,
          first_held: searchParams.get('first_held') || null,
          status: searchParams.get('status') || null,
          sort_by: searchParams.get('sort_by') || null,
          sort_direction: searchParams.get('sort_direction') || null,
        },
        cancelToken: newCancelToken(),
      }
      const response: any = await A1BatchService.getA1BatchOrders(config)
      if (response) {
        let newOrder = Array.from(response.orders, (order: any) => {
          return {
            ...order,
            canCheck: true,
          }
        })
        setTableData(newOrder)
        setTableDataAll(response.all)
        setPagination(response.pagination)
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setPageDisabled(false)
      setLoading((prev) => ({...prev, first: false}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    isCancel,
    newCancelToken,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getFilteredSearchParams(searchParams, ['release_status']),
    setPageDisabled,
  ])

  const getA1Batch = useCallback(async () => {
    try {
      setLoading((prev) => ({...prev, getA1Batch: true}))
      const {data}: any = await A1BatchService.getDetail(id, {cancelToken: newCancelToken()})
      if (data) {
        setA1Batch(data)
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setLoading((prev) => ({...prev, getA1Batch: false}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancel, newCancelToken])

  useEffect(() => {
    setTable()

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTable, reloadTableDetail])

  useEffect(() => {
    getA1Batch()

    return () => {
      setA1Batch(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadTableDetail])

  const columns = useMemo(() => {
    const showEditOrderInfo = (child, row, showRequired = false) => {
      return (
        <div
          className='cursor-pointer'
          onClick={() => {
            setDataModal((prev) => ({...prev, editOrderInfo: row.original}))
            setShowModal((prev) => ({...prev, editOrderInfo: true}))
          }}
        >
          {(showRequired && (
            <div className='d-flex text-danger'>
              <KTSVG path='/media/gori/common/invalid.svg' className='svg-icon-3 text-danger' />
              <span className='ms-2 text-decoration-underline text-uppercase'>
                {intl.formatMessage({id: 'REQUIRED'})}
              </span>
            </div>
          )) ||
          child ? (
            <span className='text-hover-primary'>{child}</span>
          ) : null}
        </div>
      )
    }

    const _columns = [
      {
        id: 'created_at',
        Header: intl.formatMessage({id: 'DATE'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => {
          return showEditOrderInfo(
            row.original?.created_at &&
              convertUserTimeZone(row.original?.created_at, currentUser, FORMAT_DATE.DATE),
            row
          )
        },
      },
      {
        id: 'code',
        Header: 'BAE ID',
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => showEditOrderInfo(row.original.bae_id, row),
      },
      {
        id: 'ship_to',
        Header: intl.formatMessage({id: 'SHIP_TO'}),
        headerClassName: 'min-w-200px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => {
          const {to_street1, to_street2, to_city, to_state, to_zip} = row.original.order_ext
          const addressTo = compact([
            to_street1,
            to_street2,
            to_city,
            to_state && to_zip ? `${to_state} ${to_zip}` : to_state || to_zip,
          ]).join(', ')
          return showEditOrderInfo(addressTo, row)
        },
      },
      {
        id: 'tracking_number',
        Header: intl.formatMessage({id: 'TRACKING_NUMBER'}),
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => {
          return showEditOrderInfo(row?.original?.tracking_number, row)
        },
      },
      {
        id: 'hawb',
        Header: 'HAWB',
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => {
          return showEditOrderInfo(row?.original?.hawb, row)
        },
      },
      {
        id: 'container_id',
        Header: intl.formatMessage({id: 'CONTAINER_ID'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => {
          return showEditOrderInfo(row?.original?.container_id, row)
        },
      },
      {
        id: 'release_status',
        Header: intl.formatMessage({id: 'RELEASE_STATUS'}),
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => {
          const foundRelease =
            find(Object.values(A1_CONSTANTS.OPTIONS_RELEASE_STATUS), {
              value: row?.original?.release_status,
            }) || null
          return showEditOrderInfo(
            foundRelease && intl.formatMessage({id: foundRelease?.label}),
            row
          )
        },
      },
      {
        id: 'content',
        Header: intl.formatMessage({id: 'CONTENT'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original?.order_items) && (
            <div>
              {(row.original?.expand
                ? row.original?.order_items
                : [row.original?.order_items[0]]
              ).map((item: any, idx) => {
                const content = find(A1_CONSTANTS.OPTIONS_CONTENT_TYPE, {value: item?.a1_content})
                return showEditOrderInfo(
                  content && (
                    <div className='d-flex' key={idx}>
                      <span className='mw-200px text-truncate'>
                        {intl.formatMessage({id: content?.label})}
                      </span>
                    </div>
                  ),
                  row
                )
              })}
            </div>
          ),
      },
      {
        id: 'description',
        Header: intl.formatMessage({id: 'DESCRIPTION'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) =>
          showEditOrderInfo(
            (row.original?.expand ? row.original?.order_items : [row.original?.order_items[0]]).map(
              (item: any, idx) => {
                return (
                  <div className='d-flex' key={idx}>
                    <span className='mw-200px text-truncate'>{item?.a1_description}</span>
                  </div>
                )
              }
            ),
            row
          ),
      },
      {
        id: 'hts_code',
        Header: intl.formatMessage({id: 'HTS_CODE'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) =>
          showEditOrderInfo(
            (row.original?.expand ? row.original?.order_items : [row.original?.order_items[0]]).map(
              (item: any, idx) => {
                return (
                  <div className='d-flex' key={idx}>
                    <span className='mw-200px text-truncate'>{item?.a1_hs_tariff_number}</span>
                  </div>
                )
              }
            ),
            row
          ),
      },
      {
        id: 'country',
        Header: intl.formatMessage({id: 'COUNTRY_OF_ORIGIN'}),
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) =>
          showEditOrderInfo(
            (row.original?.expand ? row.original?.order_items : [row.original?.order_items[0]]).map(
              (item: any, idx) => {
                const country = find(OPTION_COUNTRIES, {value: item?.a1_country})
                return (
                  country && (
                    <div className='d-flex' key={idx}>
                      <span className='mw-200px text-truncate'>{country?.label}</span>
                    </div>
                  )
                )
              }
            ),
            row
          ),
      },
      {
        id: 'quantity',
        Header: intl.formatMessage({id: 'QUANTITY'}),
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) =>
          showEditOrderInfo(
            (row.original?.expand ? row.original?.order_items : [row.original?.order_items[0]]).map(
              (item: any, idx) => {
                return (
                  <div className='d-flex justify-content-center' key={idx}>
                    <span className='mw-200px text-truncate'>{item?.a1_quantity}</span>
                  </div>
                )
              }
            ),
            row
          ),
      },
      {
        id: 'value',
        Header: intl.formatMessage({id: 'VALUES_$'}),
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-center text-gray-800',
        Cell: ({row}: {row: any}) =>
          showEditOrderInfo(
            !isEmpty(row.original?.order_items) && (
              <div className='d-flex flex-column align-items-center'>
                {(row.original?.expand
                  ? row.original?.order_items
                  : [row.original?.order_items[0]]
                ).map((item: any, idx) => {
                  const formatNumber = (number = 0) => {
                    return intl.formatNumber(Number(number), {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }

                  return (
                    <div className='d-flex' key={idx}>
                      <span className='mw-200px text-truncate'>{formatNumber(item?.a1_value)}</span>
                    </div>
                  )
                })}
              </div>
            ),
            row
          ),
      },
      {
        id: 'action',
        Header: '',
        headerClassName: 'text-center',
        cellClassName: 'text-center',
        Cell: ({row}: {row: any}) => (
          <div
            className={clsx(
              'text-primary text-hover-active-primary fw-bold cursor-pointer text-decoration-underline',
              {
                'd-none': size(row.original.order_items) <= 1,
                'min-w-150px': size(row.original.order_items) > 1,
              }
            )}
            onClick={() => {
              setTableData(
                setLoadingActionTable(tableData, [row.original.id], !row.original.expand, 'expand')
              )
            }}
          >
            {intl.formatMessage({id: row.original.expand ? 'COLLAPSE' : 'VIEW_MORE_ITEMS'})}
          </div>
        ),
      },
    ]

    if (isDestination) {
      const canCheck: any = {
        id: TABLE_KEY_COLUMN.CAN_CHECK,
        Header: intl.formatMessage({id: 'ID'}),
        accessor: 'canCheck',
      }
      _columns.splice(1, 0, canCheck)
    }

    return _columns.filter((col) => col)
  }, [intl, isDestination, currentUser, tableData])

  const handleConfirmPickupDate = useCallback(() => {
    setShowModal((prev) => ({...prev, confirmPickUpdate: true}))
  }, [])

  const handleMarkAsPickedUp = useCallback(
    async ({hasPass = false}) => {
      if (!hasPass) {
        setDataModal((prev) => ({
          ...prev,
          confirmJourneyStatus: {
            title: intl.formatMessage({id: 'MARK_AS_PICKED_UP'}),
            callback: () => handleMarkAsPickedUp({hasPass: true}),
          },
        }))
        return setShowModal((prev) => ({...prev, confirmJourneyStatus: true}))
      }

      try {
        setLoading((prev) => ({...prev, markAsPickedUp: true}))
        const {message}: any = await A1BatchService.markAsPickedUp(id, {
          cancelToken: newCancelToken(),
        })
        if (message) {
          toast.success(message)
          getA1Batch()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, markAsPickedUp: false}))
      }
    },
    [getA1Batch, id, intl, isCancel, newCancelToken]
  )

  const handleNotifyDestination = useCallback(
    async ({hasPass = false}) => {
      if (!hasPass) {
        setDataModal((prev) => ({
          ...prev,
          confirmJourneyStatus: {
            title: intl.formatMessage({id: 'NOTIFY_DESTINATION_PARTNER'}),
            callback: () => handleNotifyDestination({hasPass: true}),
          },
        }))
        return setShowModal((prev) => ({...prev, confirmJourneyStatus: true}))
      }

      try {
        setLoading((prev) => ({...prev, notifyDestination: true}))
        const {message}: any = await A1BatchService.notificationDestination(id, {
          cancelToken: newCancelToken(),
        })
        if (message) {
          toast.success(message)
          getA1Batch()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, notifyDestination: false}))
      }
    },
    [getA1Batch, id, intl, isCancel, newCancelToken]
  )

  const handleMassAsShipped = useCallback(
    async ({hasPass = false}) => {
      if (!hasPass) {
        setDataModal((prev) => ({
          ...prev,
          confirmJourneyStatus: {
            title: intl.formatMessage({id: 'MARK_AS_SHIPPED'}),
            callback: () => handleMassAsShipped({hasPass: true}),
          },
        }))
        return setShowModal((prev) => ({...prev, confirmJourneyStatus: true}))
      }

      try {
        setLoading((prev) => ({...prev, massAsShipped: true}))
        const {message}: any = await A1BatchService.markAsShipped(id, {
          cancelToken: newCancelToken(),
        })
        if (message) {
          await getA1Batch()
          toast.success(message)
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, massAsShipped: false}))
      }
    },
    [getA1Batch, id, intl, isCancel, newCancelToken]
  )

  const handleSetDropOff = useCallback(
    async ({hasPass = false}) => {
      if (!a1Batch?.dropped_off_ready) {
        return toast.warning(
          intl.formatMessage({
            id: 'ENSURE_EACH_ORDER_IN_THE_RELEASE_STATUS_COLUMN_HAS_AT_LEAST_ONE_STATUS',
          })
        )
      }

      if (!hasPass) {
        setDataModal((prev) => ({
          ...prev,
          confirmJourneyStatus: {
            title: intl.formatMessage({id: 'SET_TO_DROP_OFF'}),
            callback: () => handleSetDropOff({hasPass: true}),
          },
        }))
        return setShowModal((prev) => ({...prev, confirmJourneyStatus: true}))
      }

      try {
        setLoading((prev) => ({...prev, setDropOff: true}))
        const {message}: any = await A1BatchService.setDropOff(id, {
          cancelToken: newCancelToken(),
        })
        if (message) {
          toast.success(message)
          getA1Batch()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, setDropOff: false}))
      }
    },
    [a1Batch?.dropped_off_ready, getA1Batch, id, intl, isCancel, newCancelToken]
  )

  const handleMarkAsDroppedOff = useCallback(
    async ({hasPass = false}) => {
      if (isEmpty(a1Batch?.released_at)) {
        return toast.error(
          intl.formatMessage({
            id: 'ENSURE_AT_LEAST_ONE_RELEASED_STATUS_IN_THE_RELEASE_STATUS_COLUMN',
          })
        )
      }

      if (!hasPass) {
        setDataModal((prev) => ({
          ...prev,
          confirmJourneyStatus: {
            title: intl.formatMessage({id: 'MARK_AS_DROPPED_OFF'}),
            callback: () => handleMarkAsDroppedOff({hasPass: true}),
          },
        }))
        return setShowModal((prev) => ({...prev, confirmJourneyStatus: true}))
      }

      try {
        setLoading((prev) => ({...prev, markAsDroppedOff: true}))
        const {message}: any = await A1BatchService.markAsDroppedOff(id, {
          cancelToken: newCancelToken(),
        })
        if (message) {
          toast.success(message)
          getA1Batch()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, markAsDroppedOff: false}))
      }
    },
    [a1Batch?.released_at, getA1Batch, id, intl, isCancel, newCancelToken]
  )

  const handleCloseEditOrderInfo = useCallback(() => {
    setDataModal((prev) => ({...prev, editOrderInfo: {}}))
    setShowModal((prev) => ({...prev, editOrderInfo: false}))
  }, [])

  const handleConfirmJourneyStatus = useCallback(async () => {
    setShowModal((prev) => ({...prev, confirmJourneyStatus: false}))
    await dataModal.confirmJourneyStatus.callback()
    await setDataModal((prev) => ({
      ...prev,
      confirmJourneyStatus: {
        status: null,
        callback: null,
      },
    }))
  }, [dataModal.confirmJourneyStatus])

  const handleCloseConfirmJourneyStatus = useCallback(() => {
    setDataModal((prev) => ({
      ...prev,
      confirmJourneyStatus: {
        title: null,
        message: null,
        status: null,
        callback: null,
      },
    }))
    setShowModal((prev) => ({...prev, confirmJourneyStatus: false}))
  }, [])

  return loading.first || isEmpty(a1Batch) ? (
    <SkeletonA1BatchDetail />
  ) : (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {showModal.rename && (
        <A1BatchRenameModal
          show={showModal.rename}
          handleClose={() => setShowModal((prev) => ({...prev, rename: false}))}
        />
      )}
      {showModal.journeyInfo && (
        <JourneyInformationModal
          show={showModal.journeyInfo}
          handleClose={() => setShowModal((prev) => ({...prev, journeyInfo: false}))}
          handleSuccess={() => {
            getA1Batch()
            setTable()
            setShowModal((prev) => ({...prev, journeyInfo: false}))
          }}
        />
      )}
      {showModal.confirmPickUpdate && (
        <ConfirmA1BatchModal
          show={showModal.confirmPickUpdate}
          handleClose={() => setShowModal((prev) => ({...prev, confirmPickUpdate: false}))}
          handleSuccess={() => {
            getA1Batch()
            setShowModal((prev) => ({...prev, confirmPickUpdate: false}))
          }}
        />
      )}
      {showModal.uploadFileModal && (
        <UploadFileModal
          show={showModal.uploadFileModal}
          handleClose={() => setShowModal((prev) => ({...prev, uploadFileModal: false}))}
          handleSuccess={() => {
            getA1Batch()
            setTable()
            setShowModal((prev) => ({...prev, uploadFileModal: false}))
          }}
        />
      )}
      {showModal.t86DocumentModal && (
        <T86DocumentModal
          show={showModal.t86DocumentModal}
          handleClose={() => setShowModal((prev) => ({...prev, t86DocumentModal: false}))}
        />
      )}
      {showModal.editOrderInfo && (
        <EditOrderInfo
          show={showModal.editOrderInfo}
          data={dataModal.editOrderInfo}
          handleClose={handleCloseEditOrderInfo}
        />
      )}
      {showModal.confirmJourneyStatus && (
        <ConfirmActionModal
          show={showModal.confirmJourneyStatus}
          classNameDialog='mw-750px'
          title={
            dataModal?.confirmJourneyStatus?.title ||
            intl.formatMessage({id: 'UPDATE_JOURNEY_STATUS'})
          }
          message={
            dataModal?.confirmJourneyStatus?.message || (
              <span className='fw-bold text-center'>
                <p>{intl.formatMessage({id: 'ARE_YOU_SURE_YOU_WANT_TO_PROCEED'})}</p>
                <p>
                  {intl.formatMessage({
                    id: 'THIS_ACTION_CANNOT_BE_UNDONE_PLEASE_REVIEW_CAREFULLY_BEFORE_PROCEEDING',
                  })}
                </p>
              </span>
            )
          }
          messagePrimary={intl.formatMessage({id: 'CONFIRM'})}
          messageSecondary={intl.formatMessage({id: 'CANCEL'})}
          handlePrimaryAction={handleConfirmJourneyStatus}
          handleSecondaryAction={handleCloseConfirmJourneyStatus}
          handleClose={handleCloseConfirmJourneyStatus}
        />
      )}
      <PageTitle>{intl.formatMessage({id: 'UPLOAD_A1'})}</PageTitle>
      <div className={clsx({'cursor-no-drop': loading.getA1Batch})}>
        <div className={clsx({'pe-none opacity-75': loading.getA1Batch})}>
          <div className='d-flex flex-wrap justify-content-between mb-4'>
            <div className='d-flex'>
              <ButtonBack className={'bg-page'} config={{url: '/a1-batches'}} />
              {a1Batch?.date && (
                <div className='fw-bolder'>
                  <span className='fs-3'>{a1Batch?.name}</span>
                  <div className='fs-6 mt-2 fw-bold'>
                    <span className='me-1'>
                      {convertUserTimeZone(a1Batch?.date, currentUser, FORMAT_DATE.DATE)}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {isOrigin && (
              <div>
                {READY.value.includes(a1Batch?.journey_status) && (
                  <div>
                    <Button
                      className='btn btn-teal'
                      label={intl.formatMessage({id: 'CONFIRM_PICKUP_DATE'})}
                      event={handleConfirmPickupDate}
                    />
                  </div>
                )}
                {PICK_UP_DROP_OFF.value[0] === a1Batch?.journey_status && (
                  <Button
                    className='btn btn-teal'
                    label={intl.formatMessage({id: 'MARK_AS_PICKED_UP'})}
                    loading={loading.markAsPickedUp}
                    disabled={loading.markAsPickedUp}
                    event={handleMarkAsPickedUp}
                  />
                )}
                {PICK_UP_DROP_OFF.value[1] === a1Batch?.journey_status && (
                  <Button
                    className='btn btn-teal'
                    label={intl.formatMessage({id: 'UPDATE_BATCH_STATUS'})}
                    event={() => setShowModal((prev) => ({...prev, journeyInfo: true}))}
                  />
                )}
                {PICK_UP_DROP_OFF.value[2] === a1Batch?.journey_status && (
                  <Button
                    className='btn btn-teal'
                    label={intl.formatMessage({id: 'NOTIFY_DESTINATION_PARTNER'})}
                    loading={loading.notifyDestination}
                    disabled={loading.notifyDestination}
                    event={handleNotifyDestination}
                  />
                )}
                {A1Helpers.checkJourneyStatus({
                  statusCurrent: a1Batch?.journey_status,
                  statusCheck: AIR_DELIVERY.value[0],
                }) &&
                  a1Batch?.origin_tab === A1_CONSTANTS.A1_TABS.ORIGIN.PICKED_UP.VALUE && (
                    <Button
                      className='btn btn-teal'
                      label={intl.formatMessage({id: 'MARK_AS_SHIPPED'})}
                      loading={loading.massAsShipped}
                      disabled={loading.massAsShipped}
                      event={handleMassAsShipped}
                    />
                  )}
              </div>
            )}
            {isDestination && (
              <div>
                {AIR_DELIVERY.value[0] === a1Batch?.journey_status &&
                  !(flights?.origin?.atd && flights?.destination?.ata) && (
                    <Button
                      className='btn btn-teal'
                      label={intl.formatMessage({id: 'UPDATE_BATCH_STATUS'})}
                      event={() => setShowModal((prev) => ({...prev, journeyInfo: true}))}
                    />
                  )}
                {AIR_DELIVERY.value[0] === a1Batch?.journey_status &&
                  flights?.origin?.atd &&
                  flights?.destination?.ata && (
                    <Button
                      className={clsx('btn btn-teal', {
                        'opacity-75 cursor-no-drop': !a1Batch?.dropped_off_ready,
                      })}
                      label={intl.formatMessage({id: 'SET_TO_DROP_OFF'})}
                      event={handleSetDropOff}
                      loading={loading.setDropOff}
                      disabled={loading.setDropOff}
                    />
                  )}
                {CUSTOMS.value[0] === a1Batch.journey_status && (
                  <Button
                    className={clsx('btn btn-teal', {
                      'opacity-75 cursor-no-drop': !a1Batch?.released_at,
                    })}
                    label={intl.formatMessage({id: 'MARK_AS_DROPPED_OFF'})}
                    event={handleMarkAsDroppedOff}
                    loading={loading.markAsDroppedOff}
                    disabled={loading.markAsDroppedOff}
                  />
                )}
              </div>
            )}
          </div>
          <div className='d-flex flex-wrap mb-10'>
            <div className='col-12 col-xxl-6'>
              <JourneyInfoCard
                handleEditConfirmPickupDate={() =>
                  setShowModal((prev) => ({...prev, confirmPickUpdate: true}))
                }
                handleEditJourneyInfo={() => {
                  setShowModal((prev) => ({...prev, journeyInfo: true}))
                }}
              />
            </div>
            <div className='col-12 col-xxl-6'>
              <JourneyStatusCard />
            </div>
          </div>
          <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
            <TableWrapper>
              <A1BatchesDetailFilter
                handleUploadFileModal={() =>
                  setShowModal((prev) => ({...prev, uploadFileModal: true}))
                }
                handleT86DocumentModal={() =>
                  setShowModal((prev) => ({...prev, t86DocumentModal: true}))
                }
                selectedList={{
                  selected: selectedListId,
                  selectedMaster: selectedMaster,
                  total: tableDataAll,
                }}
                reloadTable={() => {
                  getA1Batch()
                  setTable()
                }}
              />
              <TableBody>
                <Table
                  columns={columns}
                  data={tableData}
                  pagination={pagination}
                  tbodyClass='text-gray-600 fw-bold'
                  usePagination
                  useCheckBox
                  callBackSetData={(selected) => {
                    setSelectedMaster(selected?.length === pagination?.page_size)
                    setSelectedListId(selected)
                  }}
                />
              </TableBody>
            </TableWrapper>
          </CSSTransition>
        </div>
      </div>
    </>
  )
}

export {A1BatchesDetailPage}
