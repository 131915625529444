const UPLOAD_CONFIG = {
  MAX_UPLOAD: 5000,
  SIZE: 8388608,
  SUPPORTED_FORMATS: {
    CSV: {label: 'CSV', value: 'text/csv'},
    PDF: {label: 'PDF', value: 'application/pdf'},
    XLSX: {
      label: 'XLSX',
      value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    XLS: {label: 'XLS', value: 'application/vnd.ms-excel'},
    PNG: {label: 'PNG', value: 'image/png'},
    JPG: {label: 'JPG', value: 'image/jpg'},
    JPEG: {label: 'JPEG', value: 'image/jpeg'},
  },
}

const STATUS_ADD_TO_A1_BATCH = {
  ACTIVE_BOX: {
    SMART: 'smart',
    CREATE: 'create',
    EXISTING: 'existing',
  },
  ACTION: {
    CREATE: 'create',
    EXCLUDE: 'exclude',
    MOVE: 'move',
  },
}

const A1_BATCH_STATUS = {
  OPEN: 'open',
  PROCESSED: 'processed',
  ARCHIVED: 'archived',
}

const OPTIONS_JOURNEY_INFO = {
  INCOMPLETE: {label: 'INCOMPLETE', value: 'incomplete'},
  COMPLETE: {label: 'COMPLETE', value: 'complete'},
}

const OPTIONS_JOURNEY_STATUS = {
  N_A: {label: 'N_A', value: [null], color: '#FF7272'},
  READY: {label: 'READY', value: ['ready'], color: '#A9DEC8'},
  PICK_UP_DROP_OFF: {
    label: 'PICK_UP_DROP_OFF',
    value: ['pickup_dropoff', 'picked_up', 'air_delivery'], // Value 1 is Status Primary, Value 2 is Status Hidden
    color: '#085E57',
  },
  AIR_DELIVERY: {
    label: 'AIR_DELIVERY',
    value: ['air_delivery_arranged'],
    color: '#14B8A6',
  },
  CUSTOMS: {label: 'CUSTOMS', value: ['customs'], color: '#D7B3FC'},
  TRANSFERRED_TO_LOCAL_CARRIER: {
    label: 'TRANSFERRED_TO_LOCAL_CARRIER',
    value: ['transferred'],
    color: '#D7B3FC',
  },
  DELIVERY_STATUS: {label: 'DELIVERY_STATUS', value: ['delivery_status'], color: '#14B8A6'},
}

const A1_TABS = {
  ORIGIN: {
    NEW: {LABEL: 'NEW', VALUE: 'new'},
    PICKED_UP: {LABEL: 'PICKED_UP', VALUE: 'picked_up'},
    AWAITING_PICKUP: {label: 'AWAITING_PICKUP', value: 'awaiting_pickup'},
    SHIPPED: {label: 'SHIPPED', value: 'shipped'},
    CANCELLED: {label: 'CANCELLED', value: 'cancelled'},
  },
  DESTINATION: {
    NEW: {label: 'NEW', value: 'new'},
    READY_FOR_DROP_OFF: {label: 'READY_FOR_DROP_OFF', value: 'ready_for_drop_off'},
    TRANSFERRED: {label: 'TRANSFERRED', value: 'transferred'},
  },
}

const OPTIONS_ORIGIN = [
  {label: 'SZX', value: 'SZX'},
  {label: 'PEK', value: 'PEK'},
  {label: 'SHA', value: 'SHA'},
  {label: 'HAN (Vietnam)', value: 'HAN'},
]

const OPTIONS_DESTINATION = [
  {label: 'LAX', value: 'LAX'},
  {label: 'ORD', value: 'ORD'},
  {label: 'JFK', value: 'JFK'},
  {label: 'ATL', value: 'ATL'},
]

const OPTIONS_A1_SERVICE = {
  ALL_IN_ONE: {
    label: 'A1_ALL_IN_ONE',
    value: 'all_in_one',
  },
  CUSTOMIZE: {
    label: 'CUSTOMIZE',
    value: 'customize',
    isDisabled: true,
  },
}

const OPTIONS_CONTENT_TYPE = [
  {label: 'MERCHANDISE', value: 'merchandise'},
  {label: 'GIFT', value: 'gift'},
  {label: 'DOCUMENTS', value: 'documents'},
  {label: 'SAMPLE', value: 'sample'},
  {label: 'RETURN', value: 'return'},
  {label: 'HUMANITARIAN', value: 'humanitarian'},
  {label: 'DANGEROUS_GOODS', value: 'dangerousgoods'},
  {label: 'OTHER', value: 'other'},
]

const PARAMS_A1_BATCHES_FILTER = [
  'date_from',
  'date_to',
  'name',
  'code',
  'journey_info',
  'journey_status',
  'origin_airport',
  'destination_airport',
]

const UNIT_VALUES = {
  WEIGHT_CARRIER: {
    oz: {max: 1120},
    lb: {max: 70},
    gm: {max: 31751},
    kg: {max: 31.5},
  },
  WEIGHT: {
    oz: {max: 10000},
    lb: {max: 625},
    gm: {max: 283495},
    kg: {max: 283.5},
  },
  DIMENSION: {
    in: {max: 100},
    cm: {max: 254},
    mm: {max: 2540},
  },
}

const MAX_LAYOVER = 5
const MAX_ADDITIONAL_CHARGE = 6

const SORT_KEYS_ORIGIN_VALIDATE = [
  'origin',
  'layover',
  'destination',
  'mawb',
  'shipment_detail',
  'charges',
]

const OPTIONS_RELEASE_STATUS = {
  HOLD: {label: 'HOLD', value: 'hold'},
  REJECTED: {label: 'REJECTED', value: 'rejected'},
  INFORMAL_RELEASED: {label: 'INFORMAL_RELEASED', value: 'informal_released'},
  FORMAL_RELEASED: {label: 'FORMAL_RELEASED', value: 'formal_released'},
  MANUAL_RELEASED: {label: 'MANUAL_RELEASED', value: 'manual_released'},
  DESTROYED: {label: 'DESTROYED', value: 'destroyed'},
}

const MASS_UPDATE = {
  RELEASE_STATUS: {label: 'RELEASE_STATUS', value: 'release_status'},
}

const OPTION_PACKAGE_TYPE = {
  INPUT_DIMENSION: {label: 'INPUT_DIMENSION', value: 'input_dimension'},
  CUSTOM_PACKAGE: {label: 'CUSTOM_PACKAGE', value: 'custom_package'},
  CARRIER_STANDARD_PACKAGE: {label: 'CARRIER_STANDARD_PACKAGE', value: 'carrier_standard_package'},
}

const OPTION_DIMENSION_UNIT = [
  {label: 'in', value: 'in'},
  {label: 'cm', value: 'cm'},
  {label: 'mm', value: 'mm'},
]

const OPTION_WEIGHT_UNIT = [
  {label: 'oz', value: 'oz'},
  {label: 'lb', value: 'lb'},
  {label: 'gm', value: 'gm'},
  {label: 'kg', value: 'kg'},
]

const ORDER_STATUS = {
  OPEN: 'open',
  SHIPPED: 'shipped',
  AWAITING_PAYMENT: 'awaiting_payment',
  ON_HOLD: 'on_hold',
  CANCELLED: 'cancelled',
  ERROR: 'error',
}

const DOCUMENT_TYPE = {
  T86_DOCUMENTS: {label: 'T86_DOCUMENTS', value: 't86_documents'},
}

export const A1_CONSTANTS = {
  UPLOAD_CONFIG,
  STATUS_ADD_TO_A1_BATCH,
  A1_BATCH_STATUS,
  OPTIONS_JOURNEY_INFO,
  OPTIONS_JOURNEY_STATUS,
  A1_TABS,
  OPTIONS_ORIGIN,
  OPTIONS_DESTINATION,
  OPTIONS_A1_SERVICE,
  OPTIONS_CONTENT_TYPE,
  PARAMS_A1_BATCHES_FILTER,
  UNIT_VALUES,
  MAX_LAYOVER,
  MAX_ADDITIONAL_CHARGE,
  SORT_KEYS_ORIGIN_VALIDATE,
  OPTIONS_RELEASE_STATUS,
  MASS_UPDATE,
  OPTION_PACKAGE_TYPE,
  OPTION_DIMENSION_UNIT,
  OPTION_WEIGHT_UNIT,
  ORDER_STATUS,
  DOCUMENT_TYPE,
}
