import clsx from 'clsx'
import React, {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'
import {useCancelToken, UsePermission} from '../../../../../gori/hooks'
import {useA1BatchesProvider} from '../../../../../gori/providers'
import {SelectFormik, TableHeader, ValidationErrorModal} from '../../../../../gori/widgets'
import {A1_CONSTANTS, A1BatchService, A1Helpers, MassUpdateModal} from '../../../a1'

type Props = {
  handleUploadFileModal: () => void
  handleT86DocumentModal: () => void
  selectedList?: any
  reloadTable: any
}

const A1BatchesDetailFilter: React.FC<Props> = ({
  handleUploadFileModal,
  handleT86DocumentModal,
  selectedList,
  reloadTable,
}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {isOrigin, isDestination} = UsePermission()
  const {a1Batch} = useA1BatchesProvider()
  const [loading, setLoading] = useState<{upload: boolean; export: boolean}>({
    upload: false,
    export: false,
  })
  const [validationErrors, setValidationErrors] = useState<any>()
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [dataModal, setDataModal] = useState<{
    massUpdate: {
      selectAll: boolean
      selectedList: any
      type: string
    } | null
  }>({
    massUpdate: null,
  })
  const [showModal, setShowModal] = useState<{
    massUpdate: boolean
  }>({
    massUpdate: false,
  })
  const {PICK_UP_DROP_OFF, AIR_DELIVERY, CUSTOMS} = A1_CONSTANTS.OPTIONS_JOURNEY_STATUS

  useEffect(() => {
    if (!selectedList.selectedMaster) {
      setSelectAll(false)
    }
  }, [selectedList.selected, selectedList.selectedMaster])

  const flights = useMemo(() => {
    return a1Batch?.journey_flight_infos?.reduce(
      (acc, flight) => ({
        ...acc,
        [flight.type]: flight,
      }),
      {}
    )
  }, [a1Batch?.journey_flight_infos])

  const showEditFileButton = useMemo(() => {
    return (
      (isOrigin && PICK_UP_DROP_OFF.value[2] === a1Batch?.journey_status) ||
      (isDestination &&
        ((AIR_DELIVERY.value[0] === a1Batch?.journey_status &&
          flights?.origin?.atd &&
          flights?.destination?.ata) ||
          CUSTOMS.value[0] === a1Batch?.journey_status))
    )
  }, [
    AIR_DELIVERY.value,
    CUSTOMS.value,
    PICK_UP_DROP_OFF.value,
    a1Batch?.journey_status,
    flights?.destination?.ata,
    flights?.origin?.atd,
    isDestination,
    isOrigin,
  ])

  const showT86Document = useMemo(() => {
    return A1Helpers.checkJourneyStatus({
      statusCurrent: a1Batch?.journey_status,
      statusCheck: A1_CONSTANTS.OPTIONS_JOURNEY_STATUS.PICK_UP_DROP_OFF.value[2],
    })
  }, [a1Batch?.journey_status])

  const showMassUpdate = useMemo(() => {
    return A1Helpers.checkJourneyStatus({
      statusCurrent: a1Batch?.journey_status,
      statusCheck: A1_CONSTANTS.OPTIONS_JOURNEY_STATUS.CUSTOMS.value[0],
      comparisonType: 'less_than_or_equal',
    })
  }, [a1Batch?.journey_status])

  const handleExport = async () => {
    try {
      setLoading((prev) => ({...prev, export: true}))
      const response: any = await A1BatchService.downloadOrdersA1Batch(a1Batch.id, {
        cancelToken: newCancelToken(),
      })
      if (response) {
        const blob = new Blob([response.content], {type: response.type})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = response.file_name
        link.click()
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setLoading((prev) => ({...prev, export: false}))
    }
  }

  const handleMassUpdate = (option) => {
    setShowModal((prev) => ({...prev, massUpdate: true}))
    setDataModal((prev) => ({
      ...prev,
      massUpdate: {
        selectAll: selectAll,
        selectedList: selectedList,
        type: option.value,
      },
    }))
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {showModal.massUpdate && (
        <MassUpdateModal
          show={showModal.massUpdate}
          handleClose={() => {
            setShowModal((prev) => ({...prev, massUpdate: false}))
            setDataModal((prev) => ({...prev, massUpdate: null}))
          }}
          data={dataModal.massUpdate}
          reloadTable={() => {
            reloadTable()
            setShowModal((prev) => ({...prev, massUpdate: false}))
            setDataModal((prev) => ({...prev, massUpdate: null}))
          }}
        />
      )}
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <TableHeader className='card-header border-0 d-flex  align-items-center my-5'>
          <div className='d-flex flex-fill justify-content-between align-items-center'>
            <div className='d-flex'>
              {isDestination && (
                <div
                  className={clsx('d-flex justify-content-end align-items-center flex-wrap gap-3', {
                    'd-none': selectedList.selected.length <= 0,
                  })}
                >
                  <div className='fw-bolder me-5'>
                    <span className='me-2'>
                      {selectAll ? selectedList.total.length : selectedList.selected.length}
                    </span>
                    {intl.formatMessage({id: 'SELECTED'})}
                  </div>
                  {!selectAll && selectedList.selectedMaster && (
                    <div
                      onClick={() => setSelectAll(true)}
                      className='fw-bolder me-5 link-blue text-decoration-underline cursor-pointer'
                    >
                      {intl.formatMessage({id: 'SELECT_ALL'})} {selectedList.total.length}
                    </div>
                  )}
                  {selectAll && (
                    <div
                      onClick={() => setSelectAll(false)}
                      className='fw-bolder me-5 link-blue text-decoration-underline cursor-pointer'
                    >
                      {intl.formatMessage({id: 'CLEAR_SELECTION'})}
                    </div>
                  )}
                  {showMassUpdate && (
                    <SelectFormik
                      className={clsx('w-200px fw-bold fs-5')}
                      value={{label: intl.formatMessage({id: 'MASS_UPDATE'}), value: ''}}
                      options={Object.values(A1_CONSTANTS.MASS_UPDATE)}
                      onChange={handleMassUpdate}
                      hasUseIntl={true}
                      emptyDefault={false}
                    />
                  )}
                </div>
              )}
            </div>
            <div className='d-flex'>
              {showEditFileButton && (
                <div
                  className='text-warning text-hover-primary cursor-pointer me-8 fw-bolder text-decoration-underline'
                  onClick={handleUploadFileModal}
                >
                  {intl.formatMessage({id: 'EDIT'})}
                </div>
              )}
              {showT86Document && (
                <div
                  className='text-teal text-hover-primary cursor-pointer me-8 fw-bolder text-decoration-underline'
                  onClick={handleT86DocumentModal}
                >
                  {intl.formatMessage({id: 'T86_DOCUMENTATIONS'})}
                </div>
              )}
              <div
                className={clsx(
                  'text-gray-700 text-hover-primary cursor-pointer me-4 fw-bolder text-decoration-underline'
                )}
                onClick={handleExport}
              >
                {intl.formatMessage({id: 'EXPORT_AS_CSV'})}
                {loading.export && (
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                )}
              </div>
            </div>
          </div>
        </TableHeader>
      </CSSTransition>
    </>
  )
}

export {A1BatchesDetailFilter}
